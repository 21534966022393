const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6984441', space_js: '//sfzpjbt.jtszjs.com/common/ojir_l/f/resource/f/fs.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6984442', space_js: '//sfzpjbt.jtszjs.com/common/pk-j-s/source/mgg/production/gz.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6984443', space_js: '//sfzpjbt.jtszjs.com/common/q-lk-tnhh/resource/hd.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6984444 ', space_js: '//sfzpjbt.jtszjs.com/production/rml/static/uo/iii/production/i.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6984445', space_js: '//sfzpjbt.jtszjs.com/source/s/resource/n_m/openjs/vpjj/production/jk.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    GuideNative: NATIVE_1,
    GuideBanner: BANNER,
    HomeInter: INTER,
    HomeBanner: BANNER,
    HomeNative: NATIVE_1,
    HomeNativeCenter: NATIVE_2,
    HomeNativeBottom: NATIVE_3,
    HotInter: INTER,
    HotBanner: BANNER,
    HotNative: NATIVE_1,
    DetailInter: INTER,
    DetailBanner: BANNER,
    DetailNative: NATIVE_1,
};
